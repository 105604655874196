import React from "react"
import withData from "./Card.withData"
import { backgroundCss, rootCss } from "./Card.styles"

const ContentItemContextSetup = {
  Heading: {
    css: theme => ({
      fontSize: theme.fontSizes[6],
      marginBottom: theme.space[5],
      lineHeight: theme.lineHeights.dense,

      [theme.mediaQueries.desktop]: {
        fontSize: theme.fontSizes[8],
        marginBottom: theme.space[7],
      },
    }),
    variant: `EMPHASIZED`,
    tone: `BRAND`,
  },
  Paragraph: {
    css: theme => ({
      color: theme.colors.grey[90],
      lineHeight: theme.lineHeights.loose,
      marginBottom: theme.space[8],
    }),
  },
  CTA: {
    css: _theme => ({
      marginTop: `auto`,
    }),
  },
}

function Card({ content, backgroundUrl }) {
  return (
    <div
      css={theme => [
        rootCss(theme),
        backgroundUrl && backgroundCss(backgroundUrl),
      ]}
    >
      {content.map(({ id, Component, component, data }) => {
        const contextSetup = ContentItemContextSetup[component] || {}

        return Component ? (
          <Component key={id} data={data} {...contextSetup} />
        ) : null
      })}
    </div>
  )
}

export default withData(Card)
