import React from "react"

import { ContentEditorHelper } from "./EditorHelper"

import normalizeCmsMeta from "../shared/helpers/normalizeCmsMeta"

const withData = Target => ({ data = {}, ...rest }) => {
  const analyticsId = data.analyticsId
  const anchorText = data.anchorText
  const href = data.href
  const target = data.target || `_self`

  const result = { analyticsId, anchorText, href, target }

  const cms = normalizeCmsMeta(data)

  return (
    <ContentEditorHelper cms={cms}>
      <Target {...result} {...rest} />
    </ContentEditorHelper>
  )
}

export default withData
