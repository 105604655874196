import React from "react"
import withData from "./Spacer.withData"

const heightCss = ({ theme, size }) => ({
  minHeight: theme.space[size],
})

const paddingCss = ({ theme, size }) => ({
  paddingTop: theme.space[size],
  paddingBottom: theme.space[size],
})

const hrCss = theme => ({
  background: theme.colors.grey[30],
  border: 0,
  height: `1px`,
  margin: 0,
})

function Spacer({ size, showDivider, ...rest }) {
  return (
    <div
      css={theme => [
        size && heightCss({ size, theme }),
        showDivider && size && paddingCss({ size, theme }),
      ]}
      {...rest}
    >
      {showDivider && <hr css={hrCss} />}
    </div>
  )
}

export default withData(Spacer)
