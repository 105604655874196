import React from "react"
import { MdCheck, MdClose } from "react-icons/md"
import withData from "./FeatureTable.withData"
import {
  rootCss,
  tableCss,
  columnTitleCss,
  sectionTitleCss,
  rowTitleCss,
  cellCss,
  iconCss,
} from "./FeatureTable.styles"

function FeatureTable({ columns, sections, numberOfDataColumns }) {
  const isSingleColumnTable = numberOfDataColumns === 1

  return (
    <div css={rootCss}>
      <table css={theme => tableCss({ theme, isSingleColumnTable })}>
        {columns && (
          <thead>
            <tr>
              {columns.map(({ title }, i) => {
                const { text: columnTitle } = title || {}

                return (
                  <th
                    key={`col${i}`}
                    css={theme => [
                      columnTitleCss(theme),
                      {
                        width: columnTitle
                          ? `calc(50% / ${numberOfDataColumns})`
                          : `auto`,
                      },
                    ]}
                  >
                    {columnTitle}
                  </th>
                )
              })}
            </tr>
          </thead>
        )}
        {sections && (
          <tbody>
            {sections.map(({ title, rows }, i) => {
              const { text: sectionTitle } = title || {}

              return (
                <React.Fragment key={`sec${i}`}>
                  <tr>
                    <th css={sectionTitleCss}>{sectionTitle}</th>
                  </tr>
                  {rows.map(({ title, data }, j) => {
                    const { text: rowTitle, link: rowTitleLink } = title

                    return (
                      <tr key={`row${i}${j}`}>
                        <th css={rowTitleCss}>
                          {rowTitle}{" "}
                          {rowTitleLink && (
                            <a
                              href={rowTitleLink.href}
                              target={rowTitleLink.target}
                              css={{
                                fontWeight: `normal`,
                              }}
                              rel="noopener noreferrer"
                            >
                              {rowTitleLink.text}
                            </a>
                          )}
                        </th>
                        {data.map(({ text, check }, k) => (
                          <td key={`cell${i}${j}${k}`} css={cellCss}>
                            {text}
                            {check === true && (
                              <MdCheck
                                css={theme => [
                                  iconCss(theme),
                                  {
                                    fill: theme.colors.grey[90],
                                  },
                                ]}
                              />
                            )}
                            {check === false && (
                              <MdClose
                                css={theme => [
                                  iconCss(theme),
                                  {
                                    fill: theme.colors.red[90],
                                  },
                                ]}
                              />
                            )}
                          </td>
                        ))}
                      </tr>
                    )
                  })}
                </React.Fragment>
              )
            })}
          </tbody>
        )}
      </table>
    </div>
  )
}

export default withData(FeatureTable)
