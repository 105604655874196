import React from "react"

function DoodleRight(props) {
  return (
    <svg
      width="442"
      height="3071"
      viewBox="0 0 442 3071"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M550.744 529C460.6 583.63 374.941 634.77 313.579 686.549C190.173 790.675 147.124 943.777 222.937 1123.49C290.292 1283.12 410.989 1426.49 548.724 1558.04"
        stroke="#B17ACC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M553.999 709C438.205 664.56 353.502 591.4 319.686 494.045C294.804 422.407 300.253 345.674 330.845 269.782C357.566 203.435 403.504 137.714 465.164 76.5932C491.919 50.0976 521.614 24.455 553.999 0L553.999 709Z"
        fill="url(#doodleRight_paint0_linear)"
      />
      <path
        d="M1266 3071L806.546 2456.75L479.853 2020L-5.212e-05 2503.96L414.591 2890.34L608.445 3071L1266 3071Z"
        fill="url(#doodleRight_paint1_linear)"
      />
      <defs>
        <linearGradient
          id="doodleRight_paint0_linear"
          x1="688.193"
          y1="990.827"
          x2="373.479"
          y2="-38.5555"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#362066" stopOpacity="0" />
          <stop offset="0.741115" stopColor="#362066" stopOpacity="0.741115" />
          <stop offset="1" stopColor="#362066" />
        </linearGradient>
        <linearGradient
          id="doodleRight_paint1_linear"
          x1="355.067"
          y1="2455.25"
          x2="1327.81"
          y2="2949.21"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B399CC" stopOpacity="0" />
          <stop offset="1" stopColor="#B399CC" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default DoodleRight
