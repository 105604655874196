import React from "react"
import CustomContent from "./CustomContent"
import Card from "./Card"
import Picture from "./Picture"

import { ContentEditorHelper } from "./EditorHelper"

import normalizeContent from "../shared/helpers/normalizeContent"
import normalizeCmsMeta from "../shared/helpers/normalizeCmsMeta"

const Components = {
  ContentfulCustomContent: CustomContent,
  ContentfulPicture: Picture,
  Card: Card,
}

const ComponentNames = {
  ContentfulCustomContent: `CustomContent`,
  ContentfulPicture: `Picture`,
  Card: `Card`,
}

const withData = Target => ({ data = {}, ...rest }) => {
  const numberOfColumns = data.numberOfColumns || 2
  const columnGap = data.columnGap || 10
  const rowGap = data.rowGap || 8
  const rawContent = data.content || []

  const content = normalizeContent({
    data: rawContent,
    components: Components,
    names: ComponentNames,
  })

  const result = { numberOfColumns, columnGap, rowGap, content }

  const cms = normalizeCmsMeta(data)

  return (
    <ContentEditorHelper cms={cms}>
      <Target {...result} {...rest} />
    </ContentEditorHelper>
  )
}

export default withData
