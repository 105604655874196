export const backgroundCss = url => ({
  backgroundImage: `url(${url})`,
  backgroundPosition: `bottom right`,
  backgroundRepeat: `no-repeat`,
})

export const rootCss = theme => ({
  alignItems: `flex-start`,
  background: theme.colors.whiteFade[90],
  backgroundSize: `60%`,
  border: `2px solid ${theme.colors.purple[60]}`,
  borderRadius: theme.radii[2],
  display: `flex`,
  flexDirection: `column`,
  padding: `${theme.space[7]} ${theme.space[7]} ${theme.space[8]}`,
  position: `relative`,

  [theme.mediaQueries.desktop]: {
    padding: `${theme.space[9]} ${theme.space[11]} ${theme.space[10]}`,
    backgroundSize: `auto`,
  },
})
