export const headerCss = ({ theme }) => ({
  alignContent: `center`,
  display: `grid`,
  gap: theme.space[5],
  position: `relative`,
  textAlign: `center`,
  paddingTop: `6rem`,
  paddingBottom: `1rem`,
})

export const heightCss = ({ theme }) => [
  {
    minHeight: `600px`,
    paddingBottom: `6rem`,
    paddingTop: `3rem`,

    [theme.mediaQueries.desktop]: {
      minHeight: `800px`,
    },

    [theme.mediaQueries.hd]: {
      minHeight: `900px`,
    },
  },
]

export const backgroundCss = ({ bgImageUrl, theme }) => ({
  backgroundImage: `url(${bgImageUrl})`,
  backgroundPosition: `bottom center`,
  backgroundRepeat: `no-repeat`,
  backgroundSize: `cover`,

  [theme.mediaQueries.desktop]: {
    fontSize: theme.fontSizes[15],
  },
})

export const titleCss = ({ theme, isInverted }) => ({
  fontSize: theme.fontSizes[10],
  letterSpacing: theme.letterSpacings.tight,
  lineHeight: theme.lineHeights.solid,
  color: isInverted ? theme.colors.white : theme.colors.black,

  [theme.mediaQueries.desktop]: {
    fontSize: theme.fontSizes[15],
  },
})

export const subtitleCss = ({ theme, isInverted }) => ({
  fontSize: theme.fontSizes[5],
  lineHeight: theme.lineHeights.solid,
  color: isInverted ? theme.colors.white : theme.colors.black,

  [theme.mediaQueries.desktop]: {
    fontSize: theme.fontSizes[7],
  },
})

export const ledeCss = ({ theme, isInverted }) => ({
  color: isInverted ? theme.colors.white : theme.colors.grey[90],
  fontSize: theme.fontSizes[4],
  padding: `${theme.space[4]} 0 0 0`,
  whiteSpace: `pre-wrap`,

  p: {
    marginBottom: theme.space[6],

    "&:last-of-type": {
      marginBottom: 0,
    },
  },

  [theme.mediaQueries.desktop]: {
    fontSize: theme.fontSizes[3],
    padding: `${theme.space[4]} 10% 0 10%`,
  },
})
