import React from "react"

const withData = Target => ({ data = {}, ...rest }) => {
  const src = data.image?.file?.url
  const alt = data.alternateText || ``

  const result = { src, alt }

  return <Target {...result} {...rest} />
}

export default withData
