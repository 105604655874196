import React from "react"

import { ContentEditorHelper } from "./EditorHelper"

import normalizeCmsMeta from "../shared/helpers/normalizeCmsMeta"

const withData = Target => ({ data = {}, ...rest }) => {
  const columns = data.data?.columns || []
  const sections = data.data?.sections || []
  const numberOfDataColumns = columns.length ? columns.length - 1 : 0

  const result = { columns, sections, numberOfDataColumns }

  const cms = normalizeCmsMeta(data)

  return (
    <ContentEditorHelper cms={cms}>
      <Target {...result} {...rest} />
    </ContentEditorHelper>
  )
}

export default withData
