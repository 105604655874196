import normalizeInput from "./normalizeInput"
import normalizeContentfulType from "./normalizeContentfulType"

/**
 * This helper reads data pulled from Contentful,
 * checks if there is a corresponding component
 * to render such a content,
 * if not the content is ommited
 */
function normalizeContent({ data, components, names }) {
  return data.reduce((acc, item) => {
    const { id, component, name, __typename: type } = item

    // There are two ways of identifying a corresponding component for data
    // the default one, based on the native Contentful source plugin ___typename
    // the second one, based on what the editor set in 'component' data field.
    const key = component ? normalizeInput(component) : type

    const Component = components[key]

    if (Component) {
      return [
        ...acc,
        {
          id,
          Component,
          component: names[key],
          cmsStamp: `${normalizeContentfulType(type)} ${name}`,
          data: item,
        },
      ]
    }

    return acc
  }, [])
}

export default normalizeContent
