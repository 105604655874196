import React from "react"

const withData = Target => ({ data = {}, ...rest }) => {
  const htmlText = data.text?.text

  const result = { htmlText }

  return <Target {...result} {...rest} />
}

export default withData
