import Heading from "./Heading"
import Paragraph from "./Paragraph"
import CTA from "./CTA"

const Components = {
  ContentfulHeading: Heading,
  ContentfulParagraph: Paragraph,
  ContentfulCta: CTA,
}

const ComponentNames = {
  ContentfulHeading: `Heading`,
  ContentfulParagraph: `Paragraph`,
  ContentfulCta: `CTA`,
}

export function readContentfulData({ content }) {
  const result = {
    content: [],
  }

  if (content) {
    content.forEach(item => {
      const { id, component, __typename: contentfulType } = item

      const componentKey = component
        ? component.replace(/\s/g, "")
        : contentfulType

      result.content.push({
        id,
        Component: Components[componentKey],
        component: ComponentNames[componentKey],
        data: item,
      })

      if (componentKey === `ContentfulPicture`) {
        result.backgroundUrl = item.image?.file?.url
      }
    })
  }

  return result
}
