import React from "react"
import Heading from "./Heading"
import Paragraph from "./Paragraph"
import Spacer from "./Spacer"
import CTA from "./CTA"
import CustomContent from "./CustomContent"
import List from "./List"
import Testimonial from "./Testimonial"
import FeatureTable from "./FeatureTable"
import Card from "./Card"
import Grid from "./Grid"

import { ContentEditorHelper } from "./EditorHelper"

import normalizeInput from "../shared/helpers/normalizeInput"
import normalizeContent from "../shared/helpers/normalizeContent"
import normalizeCmsMeta from "../shared/helpers/normalizeCmsMeta"

const Components = {
  ContentfulHeading: Heading,
  ContentfulParagraph: Paragraph,
  ContentfulSpacer: Spacer,
  ContentfulCta: CTA,
  ContentfulCustomContent: CustomContent,
  ContentfulList: List,
  ContentfulTestimonial: Testimonial,
  FeatureTable: FeatureTable,
  ContentfulGrid: Grid,
  Card: Card,
}

const ComponentNames = {
  ContentfulHeading: `Heading`,
  ContentfulCustomContent: `CustomContent`,
  ContentfulList: `List`,
  ContentfulTestimonial: `Testimonial`,
  ContentfulSpacer: `Spacer`,
  ContentfulGrid: `Grid`,
  ContentfulParagraph: `Paragraph`,
  ContentfulCta: `CTA`,
  Card: `Card`,
  FeatureTable: `FeatureTable`,
}

const DEFAULT_GAP = 9
const DEFAULT_VARIANT = `Default`

const withData = Target => ({ data = {}, ...rest }) => {
  const contentGap = data.contentGap || DEFAULT_GAP
  const variant = normalizeInput(data.variant) || DEFAULT_VARIANT
  const rawContent = data.content || []

  const content = normalizeContent({
    data: rawContent,
    components: Components,
    names: ComponentNames,
  })

  const result = { contentGap, variant, content }

  const cms = normalizeCmsMeta(data)

  return (
    <ContentEditorHelper cms={cms}>
      <Target {...result} {...rest} />
    </ContentEditorHelper>
  )
}

export default withData
