import removeStringChars from "../../../../utils/removeStringChars"

/**
 * For bet editor experience the avlues of predefined inputs in Contentful
 * can contain spaces and special chars, for example "Wide & Centered"
 * but we do not want to deal with such values used as object keys
 * so we clear the unnecesary chars
 *
 * For now we clear: white spaces and '&' char
 */
function normalizeInput(input) {
  if (!input) {
    return null
  }

  return removeStringChars(input, `&|\\s`)
}

export default normalizeInput
