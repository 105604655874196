import React from "react"
import withData from "./Picture.withData"

function Picture({ src, alt, ...rest }) {
  if (!src) {
    return null
  }

  return (
    <img
      src={src}
      alt={alt}
      css={{
        margin: 0,
      }}
      {...rest}
    />
  )
}

export default withData(Picture)
