import React from "react"
import striptags from "striptags"
import PageHeader from "./components/PageHeader"
import Block from "./components/Block"
import Grid from "./components/Grid"
import ArcsDoodles from "./components/backgrounds/ArcsDoodles"
import {
  EditorHelperProvider,
  PageEditorHelper,
} from "./components/EditorHelper"
import { ThemeProvider } from "gatsby-interface"
import normalizeInput from "./shared/helpers/normalizeInput"
import normalizeContent from "./shared/helpers/normalizeContent"

export function getPageHeaderTitle(content) {
  const pageHeaderIdx = content.findIndex(
    item => item.component === `PageHeader`
  )

  const title = content[pageHeaderIdx]?.data?.title

  return title ? striptags(title) : ``
}

const Components = {
  ContentfulPageHeader: PageHeader,
  ContentfulBlock: Block,
  ContentfulGrid: Grid,
}

const ComponentNames = {
  ContentfulPageHeader: `PageHeader`,
  ContentfulBlock: `Block`,
  ContentfulGrid: `Grid`,
}

const BackgroundGraphics = {
  ArcsDoodles: ArcsDoodles,
}

const DEFAULT_GAP = `5rem`

const withData = ({ data, location }) => Target => {
  const { contentfulCustomPage } = data

  const {
    id,
    contentful_id,
    spaceId,
    slug,
    name,
    backgroundGraphic,
    content: rawContent,
    contentGap,
    seoDescription,
    seoTitle,
    socialMediaImage,
  } = contentfulCustomPage

  const GraphicBackground =
    backgroundGraphic && BackgroundGraphics[normalizeInput(backgroundGraphic)]
  const gap = !contentGap ? DEFAULT_GAP : `${contentGap}rem`

  const cms = {
    spaceId,
    entryId: contentful_id,
    entryName: name,
  }

  const content = normalizeContent({
    data: rawContent,
    components: Components,
    names: ComponentNames,
  })

  // in case there is no 'SEO title' in data we use the title pulled out
  // of the PageHeader component, the value will be defined in
  // forEach loop below (if exists)
  // et pageHeaderTitle
  const pageHeaderTitle = getPageHeaderTitle(content)

  const seo = {
    title: seoTitle ? seoTitle : pageHeaderTitle,
    description: seoDescription,
    socialMediaImageUrl: socialMediaImage?.file.url,
  }

  const result = {
    id,
    slug,
    location,
    GraphicBackground,
    gap,
    content,
    seo,
  }

  return process.env.GATSBY_PREVIEW_EDITOR_HELPER ? (
    <EditorHelperProvider>
      <ThemeProvider>
        <PageEditorHelper cms={cms} />
      </ThemeProvider>
      <Target {...result} />
    </EditorHelperProvider>
  ) : (
    <Target {...result} />
  )
}

export default withData
