import React from "react"

import { ContentEditorHelper } from "./EditorHelper"

import normalizeCmsMeta from "../shared/helpers/normalizeCmsMeta"

const withData = Target => ({ data = {}, ...rest }) => {
  const htmlText = data.text?.text
  const tag = data.tag || `h3`
  const visuallyHidden = data.visuallyHidden || false

  const result = { htmlText, tag, visuallyHidden }

  const cms = normalizeCmsMeta(data)

  return (
    <ContentEditorHelper cms={cms}>
      <Target {...result} {...rest} />
    </ContentEditorHelper>
  )
}

export default withData
