import React from "react"

import { ContentEditorHelper } from "./EditorHelper"

import normalizeCmsMeta from "../shared/helpers/normalizeCmsMeta"

const withData = Target => ({ data = {}, ...rest }) => {
  const items =
    data.items
      .filter(i => i.__typename === `ContentfulCustomContent`)
      .map(i => {
        return i.content.reduce(
          (acc, j) => {
            if (j.__typename === `ContentfulPicture`) {
              const url = j.image?.file?.url
              const alt = j.alternateText
              const caption = j.caption

              acc.picture = {
                url,
                alt,
                caption,
              }
            } else if (j.__typename === `ContentfulHeading`) {
              acc.heading = {
                text: j.text.text,
              }
            } else if (j.__typename === `ContentfulParagraph`) {
              acc.paragraph = {
                text: j.text.text,
              }
            }

            return acc
          },
          { id: i.id }
        )
      }) || []

  const result = { items }

  const cms = normalizeCmsMeta(data)

  return (
    <ContentEditorHelper cms={cms}>
      <Target {...result} {...rest} />
    </ContentEditorHelper>
  )
}

export default withData
