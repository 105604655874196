import { graphql } from "gatsby"
import { PartnershipPage } from "../../components/PartnershipPage"
import withData from "../../components/PartnershipPage/withData"

function CustomPagePartnershipTemplate(props) {
  return withData(props)(PartnershipPage)
}

export default CustomPagePartnershipTemplate

export const pageQuery = graphql`
  query CustomPagePartnershipTemplateQuery($id: String!) {
    contentfulCustomPage(id: { eq: $id }) {
      ...CustomPartnershipPage
    }
  }
`
