import React from "react"
import { Global, css } from "@emotion/core"

const PAGE_HELPER_MIN_HEIGHT = `60px`

export const pageHelperCss = theme => ({
  alignItems: `center`,
  background: theme.colors.purple[90],
  bottom: 0,
  color: theme.colors.white,
  display: `grid`,
  gridTemplateColumns: `1fr`,
  gridGap: theme.space[3],
  fontSize: theme.fontSizes[1],
  flexDirection: `column`,
  justifyContent: `space-between`,
  minHeight: PAGE_HELPER_MIN_HEIGHT,
  padding: `${theme.space[3]} ${theme.space[6]} `,
  position: `fixed`,
  width: `100%`,
  zIndex: 100000,

  [theme.mediaQueries.desktop]: {
    gridTemplateColumns: `repeat(3, auto)`,
  },
})

export const purpleTextCss = theme => ({
  color: theme.colors.purple[30],
})

export const whiteTextCss = theme => ({
  color: theme.colors.white,
})

export const pageEditLinkCss = theme => ({
  color: theme.colors.white,
  svg: {
    verticalAlign: `middle`,
    marginLeft: theme.space[2],
  },
})

export const PageHelperGlobalCss = () => (
  <Global
    styles={css`
      body {
        padding-bottom: ${PAGE_HELPER_MIN_HEIGHT};
      }
    `}
  />
)

const BORDER_WIDTH = `1px`
const ICON_SIZE = `24px`

export const contentHelperCss = theme => ({
  border: `${BORDER_WIDTH} dashed ${theme.colors.red[70]}`,
  position: `relative`,
  display: `inline-grid`,
})

export const contentButtonCss = ({ theme, isExpanded }) => ({
  background: theme.colors.red[70],
  width: ICON_SIZE,
  height: ICON_SIZE,
  fontSize: theme.fontSizes[4],
  display: `flex`,
  alignItems: `center`,
  justifyContent: `center`,
  border: `none`,
  position: `absolute`,
  left: `-${BORDER_WIDTH}`,
  top: `-${BORDER_WIDTH}`,
  cursor: `pointer`,
  zIndex: 1000001,
  opacity: isExpanded ? 0.5 : 1,
})

export const contentIconCss = theme => ({
  position: `absolute`,
  left: `50%`,
  top: `50%`,
  color: theme.colors.white,
  transform: `translate(-50%, -50%)`,
})

export const contentPopupCss = theme => ({
  background: theme.colors.red[70],
  display: `flex`,
  left: `-${BORDER_WIDTH}`,
  position: `absolute`,
  top: `-${BORDER_WIDTH}`,
  transform: `translateY(-100%)`,
  fontFamily: theme.fonts.body,
  justifyContent: `space-between`,
  lineHeight: theme.lineHeights.solid,
  zIndex: 1000002,
})

export const contentOverlayCss = theme => ({
  background: theme.colors.red[70],
  position: `absolute`,
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  opacity: 0.1,
  zIndex: 1000000,
})

export const contentEditLinkCss = theme => ({
  alignItems: `center`,
  justifyContent: `center`,
  background: theme.colors.red[90],
  color: theme.colors.white,
  display: `flex`,
  flexDirection: `column`,
  textDecoration: `none`,
  fontSize: theme.fontSizes[0],
  fontWeight: theme.fontWeights.body,
  padding: `${theme.space[2]} ${theme.space[4]}`,

  svg: {
    verticalAlign: `middle`,
    fontSize: theme.fontSizes[3],
  },
})

export const contentDetailsCss = theme => ({
  color: theme.colors.white,
  display: `grid`,
  fontSize: theme.fontSizes[0],
  gridTemplateColumns: `auto auto`,
  gridRowGap: 0,
  lineHeight: theme.lineHeights.dense,
  gridColumnGap: theme.space[4],
  padding: `${theme.space[3]}  ${theme.space[5]}`,
  paddingLeft: theme.space[3],
  fontWeight: theme.fontWeights.body,
  justifyItems: `start`,
  whiteSpace: `nowrap`,
})
