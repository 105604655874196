export const rootCss = _heme => ({
  maxWidth: `100%`,
  overflowX: `auto`,
})

export const tableCss = ({ theme, isSingleColumnTable }) => ({
  fontFamily: theme.fonts.brand,
  borderSpacing: `3px`,
  borderCollapse: `separate`,
  width: isSingleColumnTable ? `auto` : `100%`,
  margin: `0 auto`,

  [theme.mediaQueries.desktop]: {
    minWidth: `35rem`,
  },
})

export const columnTitleCss = theme => ({
  background: theme.colors.purple[60],
  color: theme.colors.white,
  padding: theme.space[3],
  fontSize: theme.fontSizes[4],
  textAlign: `center`,
  border: 0,
})

export const sectionTitleCss = theme => ({
  fontSize: theme.fontSizes[5],
  color: theme.colors.purple[60],
  border: 0,
  textAlign: `center`,
  lineHeight: theme.lineHeights.solid,
})

export const rowTitleCss = theme => ({
  background: theme.colors.grey[10],
  border: 0,
  padding: theme.space[3],
  textAlign: `center`,
  lineHeight: theme.lineHeights.solid,

  "&&:first-child": {
    // overrides Typography.js style
    paddingLeft: theme.space[3],
  },
})

export const cellCss = theme => ({
  fontSize: theme.fontSizes[3],
  background: theme.colors.grey[10],
  border: 0,
  padding: theme.space[2],
  textAlign: `center`,
  fontWeight: theme.fontWeights.semiBold,
  height: `3rem`,

  "&&:last-child": {
    // overrides Typography.js style
    paddingRight: theme.space[3],
  },
})

export const iconCss = _theme => ({
  display: `inline`,
  verticalAlign: `middle`,
  fontSize: `1.8rem`,
})
