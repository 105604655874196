import React from "react"

import DoodleLeft from "./DoodleLeft"
import DoodleRight from "./DoodleRight"
import DoodleMiddle from "./DoodleMiddle"

function PartnerBackground() {
  return (
    <div
      css={{
        position: `absolute`,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <DoodleLeft
        css={{
          position: `absolute`,
          left: 0,
          top: `20rem`,
        }}
      />

      <DoodleRight
        css={{
          position: `absolute`,
          right: 0,
          top: `8rem`,
        }}
      />

      <DoodleMiddle
        css={{
          position: `absolute`,
          top: `130rem`,
        }}
      />
    </div>
  )
}

export default PartnerBackground
