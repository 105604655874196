import React from "react"
import { readContentfulData } from "./CustomContent.helpers"

function CustomContent({ data }) {
  const { content } = readContentfulData(data) || {}

  return (
    <div>
      {content.map(({ id, Component, data }) =>
        Component ? <Component key={id} data={data} /> : null
      )}
    </div>
  )
}

export default CustomContent
