import React from "react"

import Heading from "./Heading"
import Paragraph from "./Paragraph"
import CTA from "./CTA"

import { ContentEditorHelper } from "./EditorHelper"

import normalizeCmsMeta from "../shared/helpers/normalizeCmsMeta"
import normalizeContent from "../shared/helpers/normalizeContent"

const Components = {
  ContentfulHeading: Heading,
  ContentfulParagraph: Paragraph,
  ContentfulCta: CTA,
  ContentfulPicture: `background`,
}

const ComponentNames = {
  ContentfulHeading: `Heading`,
  ContentfulParagraph: `Paragraph`,
  ContentfulCta: `CTA`,
  ContentfulPicture: `background`,
}

const withData = Target => ({ data = {}, ...rest }) => {
  const rawContent = data.content || []

  const content = normalizeContent({
    data: rawContent,
    components: Components,
    names: ComponentNames,
  })

  const backgroundIdx = content.findIndex(
    item => item.component === `background`
  )
  const backgroundUrl = content[backgroundIdx]?.data?.image?.file?.url

  content.splice(backgroundIdx, 1)

  const result = { content, backgroundUrl }

  const cms = normalizeCmsMeta(data)

  return (
    <ContentEditorHelper cms={cms}>
      <Target {...result} {...rest} />
    </ContentEditorHelper>
  )
}

export default withData
