import React from "react"
import { Heading } from "gatsby-interface"
import withData from "./PageHeader.withData"
import {
  headerCss,
  heightCss,
  backgroundCss,
  titleCss,
  subtitleCss,
  ledeCss,
} from "./PageHeader.styles"
import { baseContainerCss } from "../../shared/styles/container"

const ContentItemsProps = {
  CTA: {
    css: _theme => ({
      justifySelf: `center`,
    }),
  },
  Paragraph: {
    css: theme => ({
      justifySelf: `center`,
      fontSize: theme.fontSizes[1],
      color: theme.colors.purple[60],
    }),
  },
}

function PageHeader({
  title,
  subtitle,
  lede,
  contentBelow,
  bgImageUrl,
  bgEmbraceNav,
  isInverted,
  ...rest
}) {
  return (
    <div
      css={theme => [
        bgImageUrl && backgroundCss({ theme, bgImageUrl }),
        bgEmbraceNav && { paddingTop: `5rem` },
      ]}
    >
      <header
        css={theme => [
          baseContainerCss(theme),
          headerCss({ theme, bgEmbraceNav }),
          bgImageUrl && heightCss({ theme }),
        ]}
        {...rest}
      >
        {title && (
          <Heading
            variant="EMPHASIZED"
            dangerouslySetInnerHTML={{ __html: title }}
            as="h1"
            css={theme => titleCss({ theme, isInverted })}
          />
        )}
        {subtitle && (
          <Heading
            variant="PRIMARY"
            as="h2"
            dangerouslySetInnerHTML={{ __html: subtitle }}
            css={theme => subtitleCss({ theme, isInverted })}
          />
        )}
        {lede && (
          <div
            dangerouslySetInnerHTML={{ __html: lede }}
            css={theme => ledeCss({ theme, isInverted })}
          />
        )}
        {contentBelow &&
          contentBelow.map(({ id, Component, component, data }) => {
            const props = ContentItemsProps[component]

            return (
              <Component
                key={id}
                isInverted={isInverted}
                data={data}
                {...props}
              />
            )
          })}
      </header>
    </div>
  )
}

export default withData(PageHeader)
