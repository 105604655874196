import React from "react"
import withData from "./Paragraph.withData"

function Paragraph({ htmlText, isInverted, ...rest }) {
  return (
    <p
      dangerouslySetInnerHTML={{ __html: htmlText }}
      {...rest}
      css={theme => ({
        color: isInverted ? theme.colors.white : undefined,
        margin: 0,
        whiteSpace: `pre-wrap`,

        a: {
          color: isInverted ? theme.colors.purple[30] : undefined,
        },
      })}
    />
  )
}

export default withData(Paragraph)
