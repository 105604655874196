import React from "react"

const withData = Target => ({ data = {}, ...rest }) => {
  const size = data.size
  const showDivider = data.showDivider || false

  const result = { size, showDivider }

  return <Target {...result} {...rest} />
}

export default withData
