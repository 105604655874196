/**

 */

function normalizeContentfulType(type) {
  if (!type) {
    return null
  }

  return type.replace(`Contentful`, "").replace(/([a-z])([A-Z])/g, "$1 $2")
}

export default normalizeContentfulType
