const ICON_BOX_SIZE_MOBILE = `40px`
const ICON_BOX_SIZE_DESKTOP = `60px`

export const rootCss = theme => ({
  listStyle: `none`,
  margin: `0 auto`,
  maxWidth: `40rem`,
  display: `grid`,
  gridTemplateColumns: `100%`,
  gap: theme.space[8],
})

export const itemCss = theme => ({
  display: `flex`,
  flexDirection: `column`,
  margin: 0,

  [theme.mediaQueries.desktop]: {
    position: `relative`,
    paddingLeft: `calc(${ICON_BOX_SIZE_DESKTOP} + ${theme.space[8]}) `,
  },
})

export const imageBoxCss = theme => ({
  alignItems: `center`,
  display: `flex`,
  flexShrink: 0,
  height: ICON_BOX_SIZE_MOBILE,
  justifyContent: `center`,
  width: ICON_BOX_SIZE_MOBILE,
  marginRight: theme.space[4],
  marginBottom: theme.space[2],

  [theme.mediaQueries.desktop]: {
    alignItems: `flex-start`,
    height: ICON_BOX_SIZE_DESKTOP,
    left: 0,
    justifyContent: `center`,
    position: `absolute`,
    top: theme.space[3],
    width: ICON_BOX_SIZE_DESKTOP,
    mrginBottom: 0,
  },
})

export const imageCss = () => ({
  maxWidth: `100%`,
  maxHeight: `100%`,
})

export const headingCss = theme => ({
  alignItems: `center`,
  display: `flex`,
  color: theme.colors.purple[60],
  fontSize: theme.fontSizes[5],
  margin: 0,
})

export const paragraphCss = theme => ({
  margin: 0,
  marginTop: theme.space[3],
  fontSize: theme.fontSizes[2],
  lineHeight: theme.lineHeights.body,
  color: theme.colors.grey[90],
  flexBasis: `100%`,
  flexShrink: 0,

  [theme.mediaQueries.desktop]: {
    fontSize: theme.fontSizes[3],
    marginTop: theme.space[1],
  },
})
