import React from "react"
import Block from "./Block"
import CTA from "./CTA"

import { ContentEditorHelper } from "./EditorHelper"

import normalizeCmsMeta from "../shared/helpers/normalizeCmsMeta"
import normalizeContent from "../shared/helpers/normalizeContent"

const Components = {
  ContentfulBlock: Block,
  ContentfulCta: CTA,
}

const ComponentNames = {
  ContentfulBlock: `Block`,
  ContentfulCta: `CTA`,
}

const withData = Target => ({ data = {}, ...rest }) => {
  const title = data.title
  const subtitle = data.subtitle
  const lede = data.lede.childMarkdownRemark?.html
  const variant = data.variant
  const bgEmbraceNav = data.backgroundEmbraceNav
  const isInverted = data.invertedColors
  const bgImageUrl = data.backgroundImage?.file?.url
  const rawContentBelow = data.contentBelow || []

  const contentBelow = normalizeContent({
    data: rawContentBelow,
    components: Components,
    names: ComponentNames,
  })

  const result = {
    title,
    subtitle,
    lede,
    variant,
    bgEmbraceNav,
    isInverted,
    bgImageUrl,
    contentBelow,
  }

  const cms = normalizeCmsMeta(data)

  return (
    <ContentEditorHelper cms={cms}>
      <Target {...result} {...rest} />
    </ContentEditorHelper>
  )
}

export default withData
