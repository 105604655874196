import React from "react"
import { MdEdit, MdAdd, MdRemove } from "react-icons/md"
import { ToggleCheckbox } from "gatsby-interface"

import useOnClickOutside from "../../shared/hooks/useOnClickOutside"

import {
  PageHelperGlobalCss,
  pageHelperCss,
  purpleTextCss,
  whiteTextCss,
  pageEditLinkCss,
  contentHelperCss,
  contentButtonCss,
  contentIconCss,
  contentPopupCss,
  contentOverlayCss,
  contentEditLinkCss,
  contentDetailsCss,
} from "./EditorHelper.styles"

function contentfulLink({ spaceId, entryId }) {
  return `https://app.contentful.com/spaces/${spaceId}/entries/${entryId}`
}

const EditorHelperContext = React.createContext({})

export const EditorHelperProvider = ({ children }) => {
  const [isActive, setIsActive] = React.useState(false)

  return (
    <EditorHelperContext.Provider value={{ isActive, setIsActive }}>
      {children}
    </EditorHelperContext.Provider>
  )
}

export function PageEditorHelper({ cms }) {
  const { isActive, setIsActive } = React.useContext(EditorHelperContext)
  const { entryId, spaceId, entryName } = cms || {}

  return (
    <React.Fragment>
      <PageHelperGlobalCss />
      <div css={pageHelperCss}>
        <div>
          <span css={purpleTextCss}>
            {`You're previewing page (name):`}&nbsp;{" "}
          </span>
          <strong>{entryName}</strong>
        </div>
        <div>
          <ToggleCheckbox
            label={
              <span>
                Editor Helper:{" "}
                <strong>{isActive ? `Enabled` : `Disabled`}</strong>
              </span>
            }
            tone="BRAND"
            checked={isActive}
            onChange={() => setIsActive(!isActive)}
            css={whiteTextCss}
          />
        </div>
        <div>
          <a href={contentfulLink({ spaceId, entryId })} css={pageEditLinkCss}>
            Edit page
            <MdEdit />
          </a>
        </div>
      </div>
    </React.Fragment>
  )
}

export function ContentEditorHelper({ children, cms }) {
  const detailsRef = React.useRef()
  const { entryId, spaceId, entryName, entryType } = cms || {}
  const [isExpanded, setIsExpanded] = React.useState(false)
  const { isActive } = React.useContext(EditorHelperContext)

  useOnClickOutside(detailsRef, () => {
    setIsExpanded(false)
  })

  if (!process.env.GATSBY_PREVIEW_EDITOR_HELPER) {
    return children
  }

  if (!isActive) {
    return children
  }

  return (
    <div css={contentHelperCss}>
      {children}

      {isExpanded && <div css={contentOverlayCss} />}

      <button
        title={isExpanded ? `Hide` : `Show`}
        onClick={() => setIsExpanded(!isExpanded)}
        css={theme => contentButtonCss({ theme, isExpanded })}
      >
        {isExpanded ? (
          <MdRemove css={contentIconCss} />
        ) : (
          <MdAdd css={contentIconCss} />
        )}
      </button>

      {isExpanded && (
        <div ref={detailsRef} css={contentPopupCss}>
          <a
            href={contentfulLink({ spaceId, entryId })}
            target="preview-editor-to-cms"
            css={contentEditLinkCss}
            title="Edit content"
          >
            <MdEdit />
          </a>
          <div css={contentDetailsCss}>
            <span>Name:</span>
            <strong>{entryName}</strong>

            <span>Model:</span>
            <strong>{entryType}</strong>
          </div>
        </div>
      )}
    </div>
  )
}
