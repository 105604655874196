import React from "react"

import { ContentEditorHelper } from "./EditorHelper"

import normalizeCmsMeta from "../shared/helpers/normalizeCmsMeta"

const withData = Target => ({ data = {}, ...rest }) => {
  const author = data.author
  const jobTitle = data.jobTitle
  const company = data.company
  const url = data.url
  const quoteHtml = data.quote?.childMarkdownRemark?.html

  const result = { author, jobTitle, company, url, quoteHtml }

  const cms = normalizeCmsMeta(data)

  return (
    <ContentEditorHelper cms={cms}>
      <Target {...result} {...rest} />
    </ContentEditorHelper>
  )
}

export default withData
