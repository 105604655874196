import React from "react"
import withData from "./Testimonial.withData"

function Testimonial({ author, jobTitle, company, url, quoteHtml, ...rest }) {
  const authorHtml = `<strong>${author}</strong>`
  const jobTitleHtml = jobTitle ? `<span>${jobTitle}</span>` : ``
  const companyHtml = company ? `<span>${company}</span>` : ``

  const footerAsText = `<footer>${authorHtml}${jobTitleHtml}${companyHtml}</footer>`
  const footerAsLink = `<a href=${url}>${footerAsText}</a>`
  const footerHtml = url ? footerAsLink : footerAsText
  const allHtml = `${quoteHtml}${footerHtml}`

  return (
    <blockquote
      css={theme => ({
        border: 0,
        maxWidth: `40rem`,
        margin: `0 auto`,
        padding: 0,
        whiteSpace: `pre-wrap`,

        p: {
          fontFamily: theme.fonts.brand,
          fontSize: theme.fontSizes[5],
          fontWeight: theme.fontWeights.semiBold,
          lineHeight: theme.lineHeights.dense,
        },
        footer: {
          display: `grid`,
          fontSize: theme.fontSizes[2],
          justifyContent: `start`,

          span: {
            fontSize: theme.fontSizes[1],
          },
        },
        a: {
          color: theme.colors.grey[50],
          textDecoration: `none`,

          "&:hover": {
            color: theme.colors.purple[60],
          },
        },
      })}
      dangerouslySetInnerHTML={{ __html: allHtml }}
      {...rest}
    />
  )
}

export default withData(Testimonial)
