import React from "react"

function DoodleMiddle(props) {
  return (
    <svg
      width="1440"
      height="807"
      viewBox="0 0 1440 807"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1503.93 805.416C1190.72 758.044 979.792 415.448 629.649 417.923C451.789 419.183 269.636 473.549 101.401 404.632C-65.9055 336.096 -153.054 182.879 -196.962 27.6396C-199.469 18.8033 -201.877 9.9544 -204.197 1.08438"
        stroke="#B17ACC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  )
}

export default DoodleMiddle
