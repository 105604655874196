import React from "react"
import withData from "./Block.withData"
import { baseContainerCss } from "../../shared/styles/container"
import { greyBoxCss, defaultCss } from "./Block.styles"

const ContentItemContextSetup = {
  Heading: {
    css: theme => ({
      fontSize: theme.fontSizes[8],
      textAlign: `center`,

      [theme.mediaQueries.desktop]: {
        fontSize: theme.fontSizes[11],
      },
    }),
    variant: `EMPHASIZED`,
  },
  CTA: {
    css: _theme => ({
      justifySelf: `center`,
    }),
  },
  Paragraph: {
    css: theme => ({
      justifySelf: `center`,
      fontSize: theme.fontSizes[1],
    }),
  },
}

const DefaultContainer = ({ children, asContainer, contentGap, ...rest }) => (
  <div
    css={theme => [
      defaultCss({ theme, contentGap }),
      asContainer && baseContainerCss(theme),
    ]}
    {...rest}
  >
    {children}
  </div>
)

const GreyBoxContainer = ({ children, asContainer, contentGap, ...rest }) => (
  <div
    css={theme => [
      defaultCss({ theme, contentGap }),
      asContainer && baseContainerCss(theme),
    ]}
    {...rest}
  >
    <div css={theme => [defaultCss({ theme, contentGap }), greyBoxCss(theme)]}>
      {children}
    </div>
  </div>
)

const Block = ({
  contentGap,
  variant,
  content,
  asContainer = false,
  isInverted = false,
}) => {
  const Container = variant === `GreyBox` ? GreyBoxContainer : DefaultContainer

  return (
    <Container asContainer={asContainer} contentGap={contentGap}>
      {content.map(({ id, Component, component, data }) => {
        const contextSetup = ContentItemContextSetup[component]

        return Component ? (
          <Component
            key={id}
            data={data}
            isInverted={isInverted}
            {...contextSetup}
          />
        ) : null
      })}
    </Container>
  )
}

export default withData(Block)
