import React from "react"
import { SkipNavTarget } from "../shared/components/SkipNav"
import { CustomPageLayout } from "../CustomPageLayout"

const ContentItemsProps = {
  PageHeader: {},
  Block: {
    asContainer: true,
  },
  Grid: {
    asContainer: true,
  },
}

export function PartnershipPage(props) {
  const { content, gap, GraphicBackground, seo, location } = props

  return (
    <CustomPageLayout invertHeader={false} seo={seo} location={location}>
      {GraphicBackground && <GraphicBackground />}
      <main>
        <SkipNavTarget />
        <div
          css={{
            display: `grid`,
            gap: gap,
          }}
        >
          {content.map(({ id, Component, component, data }) => {
            const props = ContentItemsProps[component]

            return <Component key={id} data={data} {...props} />
          })}
        </div>
      </main>
    </CustomPageLayout>
  )
}
