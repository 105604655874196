import React from "react"
import withData from "./List.withData"

import {
  rootCss,
  itemCss,
  imageBoxCss,
  imageCss,
  headingCss,
  paragraphCss,
} from "./List.styles"

function List({ items, ...rest }) {
  return (
    <ul css={rootCss} {...rest}>
      {items.map(({ id, picture, heading, paragraph }) => {
        return (
          <li key={id} css={itemCss}>
            <div css={imageBoxCss}>
              <img src={picture.url} css={imageCss} alt="" />
            </div>
            <h3 css={headingCss}>{heading.text}</h3>
            <p css={paragraphCss}>{paragraph.text}</p>
          </li>
        )
      })}
    </ul>
  )
}

export default withData(List)
