import React from "react"
import { baseContainerCss } from "../../shared/styles/container"
import withData from "./Grid.withData"

const ContentItemContextSetup = {
  Picture: {
    css: _theme => ({
      alignSelf: `center`,
      justifySelf: `center`,
    }),
  },
}

const Grid = ({
  numberOfColumns,
  columnGap,
  rowGap,
  content,
  isInverted,
  asContainer = false,
}) => {
  return (
    <div
      css={theme => [
        {
          position: `relative`,
          display: `grid`,
          rowGap: theme.space[rowGap],
          columnGap: theme.space[columnGap],

          [theme.mediaQueries.desktop]: {
            gridTemplateColumns: `repeat(${numberOfColumns}, 1fr)`,
          },
        },
        asContainer && baseContainerCss(),
      ]}
    >
      {content.map(({ id, Component, component, data }) => {
        const contextSetup = ContentItemContextSetup[component] || {}

        return Component ? (
          <Component
            key={id}
            data={data}
            isInverted={isInverted}
            {...contextSetup}
          />
        ) : null
      })}
    </div>
  )
}

export default withData(Grid)
