export const defaultCss = ({ theme, contentGap }) => ({
  display: `grid`,
  position: `relative`,
  justifyContent: `center`,
  gap: contentGap === `no gap` ? 0 : `${theme.space[Number(contentGap)]}`,
})

export const greyBoxCss = theme => ({
  background: theme.colors.grey[10],
  padding: `${theme.space[10]} ${theme.space[11]}`,
})
