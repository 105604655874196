import React from "react"

function DoodleLeft(props) {
  return (
    <svg
      width="829"
      height="2557"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M-389.1 642.6c149.9-31.6 706.8-113.4 598.3-395.8C154.4 104.4-6.4 37.6-141.8 20.3"
        stroke="#B17ACC"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M140.8 2262a266.7 266.7 0 00-47-141.4l-307.7 316.6 4.4 111.6a310 310 0 00190-26.3c98.9-48.4 164.8-148 160.3-260.5z"
        fill="url(#doodleLeft_paint0_linear)"
      />
      <path
        d="M769.4 314.7l-979-150.7L-315 849.3l141-51 693.4-250.8 290.3-105-40.3-127.8z"
        fill="url(#doodleLeft_paint1_linear)"
        fillOpacity=".5"
      />
      <defs>
        <linearGradient
          id="doodleLeft_paint0_linear"
          x1="-111.2"
          y1="2554.4"
          x2="73.7"
          y2="2081"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B399CC" stopOpacity="0" />
          <stop offset="1" stopColor="#B399CC" />
        </linearGradient>
        <linearGradient
          id="doodleLeft_paint1_linear"
          x1="-289"
          y1="831"
          x2="-60.2"
          y2="322"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B399CC" />
          <stop offset="1" stopColor="#B399CC" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default DoodleLeft
