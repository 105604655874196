import React from "react"
import { AnchorButton } from "gatsby-interface"
import withData from "./CTA.withData"

// todo: create inverted variants of gatsby-interface buttons
const invertedCss = theme => ({
  color: theme.colors.purple[60],
  background: theme.colors.white,
  borderColor: theme.colors.purple[20],

  ":hover": {
    color: theme.colors.purple[70],
    background: theme.colors.white,
    borderColor: theme.colors.purple[60],
  },
})

function CTA({ analyticsId, anchorText, href, target, isInverted, ...rest }) {
  return (
    <AnchorButton
      href={href}
      target={target}
      id={analyticsId}
      css={theme => [
        {
          position: `relative`,
        },
        isInverted && invertedCss(theme),
      ]}
      {...rest}
    >
      {anchorText}
    </AnchorButton>
  )
}

export default withData(CTA)
